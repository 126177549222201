import mimeTypes from 'mime-types'
import { updatePicture, setError, uploadCard } from './actions'
import { uploadToS3 } from '../services/imageService'
import Status from './status'
import config from '../config'

const notUploaded = p => !p.src

export const uploadPictures = async (dispatch, getState) => {
  const { card, editor } = getState()
  try {
    await Promise.all(
      card.pictures.filter(notUploaded).map(async picture => {
        const { id, file } = picture
        const date = new Date().toISOString().slice(0, 10)
        const fileExtension = mimeTypes.extension(file.type)
        const filename = `${date}/${id}.${fileExtension}`
        const start = performance.now()
        const src = await uploadToS3(filename, file)
        const end = performance.now()
        const duration = Math.round(end - start)
        console.log(`image uploaded in ${duration}ms`, file, src)
        dispatch(updatePicture({ id: picture.id, src }))
      })
    )
    if (editor.status === Status.SendingCard) {
      dispatch(uploadCard)
    }
  } catch (error) {
    dispatch(setError(`Could not send the card: ${error.message}`))
  }
}
