import React from 'react'
import { connect } from 'react-redux'
import { Card, CardBody, CardHeader } from 'reactstrap'
import templates from '../templates'
import { setTemplate, setBackgroundImage } from '../state/actions'

const TemplateSelector = ({ onChange }) => (
  <Card className="template-selector">
    <CardHeader>Choose a card</CardHeader>
    <CardBody className="text-center p-1">
      {templates.map(template => (
        <button
          key={template.image}
          type="button"
          className="link-button"
          onClick={() => onChange(template)}
        >
          <img src={template.image} className="image-icon" alt="card" />
        </button>
      ))}
    </CardBody>
  </Card>
)

const mapStateToProps = state => ({
  undefined,
})

const mapDispatchToProps = {
  onChange: template => dispatch => {
    dispatch(setTemplate(template))
    dispatch(setBackgroundImage(template.image))
  },
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TemplateSelector)
