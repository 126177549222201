import React from 'react'
import { connect } from 'react-redux'
import { Button, Card, CardBody, CardHeader, Form, FormGroup, Input } from 'reactstrap'
import { setEmail, setPreview, sendCard } from '../state/actions'

const Share = ({ email, onEmailChange, onPreview, onSend }) => (
  <Card className="mt-2">
    <CardHeader>Share</CardHeader>
    <CardBody className="p-1">
      <Form onSubmit={onSend}>
        <FormGroup>
          <Input type="email" value={email} placeholder="E-mail..." onChange={onEmailChange} />
        </FormGroup>
        <Button className="float-left" type="button" onClick={onPreview}>
          Preview
        </Button>
        <Button className="float-right" disabled={!email}>
          Send
        </Button>
      </Form>
    </CardBody>
  </Card>
)

const mapStateToProps = ({ editor }) => ({
  email: editor.email,
})

const mapDispatchToProps = {
  onPreview: () => dispatch => {
    dispatch(setPreview(true))
  },

  onEmailChange: e => dispatch => {
    dispatch(setEmail(e.target.value))
  },

  onSend: e => dispatch => {
    e.preventDefault()
    dispatch(sendCard)
  },
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Share)
