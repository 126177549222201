import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Container, Row } from 'reactstrap'
import LoadingOverlay from 'react-loading-overlay'
import { loadCard } from '../state/actions'
import Status from '../state/status'
import GreetingCard from '../components/GreetingCard'

class View extends Component {
  componentDidMount() {
    this.props.loadCard()
  }

  render() {
    const { cardId, status, error, onReply } = this.props

    if (error) {
      return (
        <div className="center">
          Sorry, but the card {cardId} could not be loaded: {error}
        </div>
      )
    }

    if (status !== Status.CardLoaded) {
      return (
        <LoadingOverlay
          className="center"
          active
          text="Loading..."
          background="#FFF"
          color="gray"
        />
      )
    }

    return (
      <Container fluid className="view-container">
        <Row className="justify-content-center p-1">
          <GreetingCard readonly />
        </Row>
        <Row className="justify-content-center pt-2">
          <Button type="button" onClick={onReply}>
            Reply with Thank You
          </Button>
        </Row>
      </Container>
    )
  }
}
const mapStateToProps = (state, ownProps) => ({
  cardId: ownProps.match.params.cardId,
  status: state.editor.status,
  error: state.editor.error,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadCard: () => {
    const { cardId } = ownProps.match.params
    dispatch(loadCard(cardId))
  },
  onReply: () => {
    ownProps.history.push('/')
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(View)
