import { createAction } from 'redux-act'

export const setBackgroundImage = createAction('SET_BACKGROUND_IMAGE')

export const addPicture = createAction('ADD_PICTURE')
export const removePicture = createAction('REMOVE_PICTURE')
export const updatePicture = createAction('UPDATE_PICTURE')

export const addMessage = createAction('ADD_MESSAGE')
export const removeMessage = createAction('REMOVE_MESSAGE')
export const updateMessage = createAction('UPDATE_MESSAGE')

export const setCard = createAction('SET_CARD')

export const setEmail = createAction('SET_EMAIL')
export const setTemplate = createAction('SET_TEMPLATE')
export const setPreview = createAction('SET_PREVIEW')
export const setStatus = createAction('SET_STATUS')
export const setCardUrl = createAction('SET_CARD_URL')
export const setError = createAction('SET_ERROR')
export const dismissAlerts = createAction('DISMISS_ALERTS')
