import React, { Component } from 'react'
import { Button, Container, Row } from 'reactstrap'
import GreetingCard from '../components/GreetingCard'

const Preview = props => {
  const state = props.location.state || props
  return (
    <Container fluid className="main-container">
      <Row className="justify-content-center p-1">
        <GreetingCard {...state} readonly />
      </Row>
      <Row className="justify-content-center">
        <Button type="button" onClick={() => props.history.goBack()}>
          Back
        </Button>
      </Row>
    </Container>
  )
}

export default Preview
