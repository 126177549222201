import React, { Component } from 'react'
import Rnd from 'react-rnd-rotate'
import ClickOutHandler from 'react-onclickout'
import { CustomInput } from 'reactstrap'
import removeIcon from '../images/remove-icon.png'
import rotateIcon from '../images/rotate-icon.png'

class Shape extends Component {
  position = this.props.position

  state = {
    isActive: false,
  }

  handleDrag = (e, data) => {
    const { x, y } = data
    this.position = { ...this.position, x, y }
    this.props.onChange(this.position)
  }

  handleResize = (e, direction, ref, delta, position) => {
    const width = delta.newWidth
    const height = delta.newHeight
    const degree = delta.degree || 0
    this.position = { ...this.position, width, height, degree }
    this.props.onChange(this.position)
  }

  handleRadiusChange = e => {
    const radius = Number(e.target.value)
    this.position = { ...this.position, radius }
    this.props.onChange(this.position)
  }

  setActive = e => {
    this.setState({ isActive: true })
  }

  setInactive = e => {
    this.setState({ isActive: false })
  }

  handleRemove = e => {
    this.props.onRemove()
  }

  render() {
    const {
      position: initialPosition,
      lockAspectRatio,
      enableRadiusChange,
      fixed,
      children,
    } = this.props

    const { x, y, width, height, degree, radius, zIndex = 0 } = initialPosition

    const borderSize = '2px'
    const borderColor = '#0a91e0'

    if (!this.state.isActive || fixed) {
      const fixedStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        left: x,
        top: y,
        width,
        height,
        transform: `rotate(${degree}deg)`,
        padding: borderSize,
        zIndex,
      }

      return (
        <div style={fixedStyle} tabIndex="-1" onFocus={this.setActive}>
          {children}
        </div>
      )
    }

    const activeStyle = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: `${borderSize} solid ${borderColor}`,
      zIndex: this.state.isActive ? zIndex + 1 : zIndex,
    }

    const resizeHandleBorder = `8px solid ${borderColor}`

    const resizeHandleStyles = {
      topLeft: {
        width: '16px',
        height: '16px',
        left: '-8px',
        top: '-8px',
        border: resizeHandleBorder,
      },
      topRight: {
        width: '16px',
        height: '16px',
        right: '-8px',
        top: '-8px',
        border: resizeHandleBorder,
      },
      bottomRight: {
        width: '16px',
        height: '16px',
        right: '-8px',
        bottom: '-8px',
        border: resizeHandleBorder,
      },
      bottomLeft: {
        width: '16px',
        height: '16px',
        bottom: '-8px',
        left: '-8px',
        border: resizeHandleBorder,
      },
      rotate: {
        width: '48px',
        height: '48px',
        left: '50%',
        top: '-48px',
        marginLeft: '-24px',
        backgroundImage: `url(${rotateIcon})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        cursor: `url(${rotateIcon}) 8 8, default`,
      },
    }

    const toolbarStyle = {
      position: 'absolute',
      top: -30,
      width: '100%',
    }

    const removeIconStyle = {
      position: 'absolute',
      top: -10,
      right: 20,
    }

    const radiusInputStyle = {
      position: 'absolute',
      bottom: -26,
      width: '80%',
    }

    return (
      <ClickOutHandler onClickOut={this.setInactive}>
        <Rnd
          style={activeStyle}
          resizeHandleStyles={resizeHandleStyles}
          bounds="parent"
          default={initialPosition}
          minWidth="50"
          minHeight="50"
          onDragStop={this.handleDrag}
          onResizeStop={this.handleResize}
          lockAspectRatio={lockAspectRatio}
          keyMoveEnabled={false}
          cancel=".non-draggable"
        >
          <div style={toolbarStyle}>
            <button
              type="button"
              style={removeIconStyle}
              className="link-button"
              onClick={this.handleRemove}
            >
              <img src={removeIcon} alt="remove" />
            </button>
          </div>
          {children}
          {enableRadiusChange && (
            <CustomInput
              id="radius"
              type="range"
              style={radiusInputStyle}
              className="non-draggable"
              min="0"
              max="50"
              step="1"
              value={radius}
              onChange={this.handleRadiusChange}
            />
          )}
        </Rnd>
      </ClickOutHandler>
    )
  }
}

export default Shape
