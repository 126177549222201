import { setStatus, setError, uploadPictures, setCard, setCardUrl, dismissAlerts } from './actions'
import Status from './status'
import { postCard, getCard } from '../services/cardService'

export const uploadCard = async (dispatch, getState) => {
  const { card, editor } = getState()
  try {
    const data = {
      card: {
        backgroundImage: card.backgroundImage,
        pictures: card.pictures.map(p => ({
          id: p.id,
          src: p.src,
          position: p.position,
          file: {
            name: p.file.name,
            type: p.file.type,
            size: p.file.size,
          },
        })),
        messages: card.messages,
      },
      email: editor.email,
    }
    const response = await postCard(data)
    console.log('card sent', response)
    dispatch(setCardUrl(response.data.cardUrl))
    dispatch(setStatus(Status.CardSent))
  } catch (error) {
    dispatch(setError(`Could not send the card: ${error.message}`))
  }
}

export const sendCard = async (dispatch, getState) => {
  dispatch(dismissAlerts())
  dispatch(setStatus(Status.SendingCard))
  const { card } = getState()
  if (card.pictures.some(p => !p.src)) {
    dispatch(uploadPictures)
  } else {
    dispatch(uploadCard)
  }
}

export const loadCard = cardId => async dispatch => {
  try {
    const data = await getCard(cardId)
    dispatch(setCard(data))
    dispatch(setStatus(Status.CardLoaded))
  } catch (error) {
    dispatch(setError(`Could not load the card: ${error.message}`))
  }
}
