import React from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'

import Amplify from '@aws-amplify/core'
import Auth from '@aws-amplify/auth'
import awsconfig from './aws-exports'

import Main from './containers/Main'
import Preview from './containers/Preview'
import View from './containers/View'

Amplify.configure(awsconfig)
Auth.configure(awsconfig)

const App = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={Main} />
      <Route path="/preview" component={Preview} />
      <Route path="/cards/:cardId" component={View} />
      <Redirect from="*" to="/" />
    </Switch>
  </BrowserRouter>
)

export default App
