import Storage from '@aws-amplify/storage'
import Pica from 'pica'
import config from '../config'

const BUCKET_URL = `https://${config.images.bucketName}.s3.${config.images.region}.amazonaws.com`

export const uploadToS3 = async (filename, file) => {
  const level = 'public'
  const stored = await Storage.put(filename, file, { level, contentType: file.type })
  return `${BUCKET_URL}/${level}/${stored.key}`
}

const loadImage = async src =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.onerror = reject
    image.onload = () => resolve(image)
    image.src = src
  })

const pica = new Pica()

const resizeToFit = async (image, width, height, type) => {
  const canvas = document.createElement('canvas')
  canvas.width = width
  canvas.height = height

  const result = await pica.resize(image, canvas)

  const useJpeg = type !== 'image/png'

  return useJpeg ? pica.toBlob(result, 'image/jpeg', 0.9) : pica.toBlob(result, type)
}

const calculateSizeToFit = (image, maxWidth, maxHeight) => {
  const ratio = Math.min(maxWidth / image.width, maxHeight / image.height)
  return { width: Math.round(image.width * ratio), height: Math.round(image.height * ratio) }
}

export const calculatePosition = (image, bounds) => {
  const { width, height } = calculateSizeToFit(image, bounds.width, bounds.height)
  const x = bounds.x + Math.round((bounds.width - width) / 2)
  const y = bounds.y + Math.round((bounds.height - height) / 2)
  return { x, y, width, height }
}

export const resizeImage = async file => {
  const { name, type, size } = file

  if (!config.images.supportedMimeTypes.includes(type)) {
    throw new Error(`Image type is not supported: ${type}`)
  }

  if (size > config.images.maxFileSizeMb * 1000000) {
    throw new Error(`File size exceeds ${config.images.maxFileSizeMb} MB`)
  }

  const objectUrl = URL.createObjectURL(file)
  const image = await loadImage(objectUrl)

  const { maxWidth, maxHeight } = config.images

  if (maxWidth > image.width && maxHeight > image.height) {
    return { file, image }
  }

  const { width, height } = calculateSizeToFit(image, maxWidth, maxHeight)

  const blob = await resizeToFit(image, width, height, type)
  URL.revokeObjectURL(objectUrl)

  // using blob instead of `new File()` to workaround the issue in Edge
  // https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/9551546/
  blob.name = name
  blob.lastModifiedDate = new Date()

  const resizedObjectUrl = URL.createObjectURL(blob)
  const resizedImage = await loadImage(resizedObjectUrl)

  return { file: blob, image: resizedImage }
}
