// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile =  {
    "aws_project_region": "ap-southeast-2",
    "aws_cognito_identity_pool_id": "ap-southeast-2:0aa4e557-9ee0-4632-9d66-d8d69e307ef4",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_KMvK6qgLm",
    "aws_user_pools_web_client_id": "2ggbp150dmuhhbeglq6ib93vpn",
    "aws_user_files_s3_bucket": "greety-images",
    "aws_user_files_s3_bucket_region": "ap-southeast-2"
};


export default awsmobile;
