import React from 'react'
import { connect } from 'react-redux'
import { Alert, Button, Container, Row, Col } from 'reactstrap'
import CloseOnEscape from 'react-close-on-escape'
import LoadingOverlay from 'react-loading-overlay'
import { setPreview, dismissAlerts } from '../state/actions'
import Status from '../state/status'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Sidebar from '../components/Sidebar'
import TemplateSelector from '../components/TemplateSelector'
import ImageUploader from '../components/ImageUploader'
import MessageSelector from '../components/MessageSelector'
import Share from '../components/Share'
import GreetingCard from '../components/GreetingCard'

const Main = ({ isPreview, onPreviewClosed, status, error, onDismissAlerts }) => {
  if (isPreview) {
    return (
      <Container fluid className="main-container">
        <Row className="justify-content-center p-1">
          <CloseOnEscape onEscape={onPreviewClosed}>
            <GreetingCard readonly />
          </CloseOnEscape>
        </Row>
        <Row className="justify-content-center">
          <Button type="button" onClick={onPreviewClosed}>
            Close
          </Button>
        </Row>
      </Container>
    )
  }

  return (
    <Container fluid className="main-container">
      {/* <Header /> */}
      <Row className="flex-nowrap">
        <Col className="p-1">
          <Sidebar>
            <TemplateSelector />
          </Sidebar>
        </Col>
        <Col className="p-1">
          <CloseOnEscape onEscape={onDismissAlerts}>
            <Alert color="success" isOpen={status === Status.CardSent} toggle={onDismissAlerts}>
              The card was sent successfully!
            </Alert>
            <Alert color="danger" isOpen={!!error} toggle={onDismissAlerts}>
              {error}
            </Alert>
          </CloseOnEscape>
          <LoadingOverlay
            active={!error && status === Status.SendingCard}
            spinner
            text="Sending the card..."
          >
            <GreetingCard />
          </LoadingOverlay>
        </Col>
        <Col className="p-1">
          <Sidebar>
            <ImageUploader />
            <MessageSelector />
            <Share />
          </Sidebar>
        </Col>
      </Row>
      {/* <Footer /> */}
    </Container>
  )
}

const mapStateToProps = state => ({
  ...state.editor,
})

const mapDispatchToProps = {
  onDismissAlerts: dismissAlerts,

  onPreview: () => dispatch => {
    dispatch(setPreview(true))
  },

  onPreviewClosed: () => dispatch => {
    dispatch(setPreview(false))
  },
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Main)
