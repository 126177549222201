import { createReducer } from 'redux-act'
import { combineReducers } from 'redux'
import generate from 'nanoid/generate'
import * as actions from './actions'
import templates from '../templates'
import Status from './status'

const initialState = {
  card: {
    backgroundImage: templates[0].image,
    pictures: [],
    messages: [],
  },
  editor: {
    email: '',
    template: templates[0],
    isPreview: false,
    status: Status.Default,
    cardUrl: null,
    error: null,
  },
}

const uniqueId = () =>
  generate('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz', 16)

const defaultPicturePosition = { x: 0, y: 0, width: 200, degree: 0, radius: 0, zIndex: 1 }

const defaultMessagePosition = {
  x: 85,
  y: 80,
  width: 300,
  height: 200,
  degree: 0,
  radius: 0,
  zIndex: 1,
}

const cardReducer = createReducer(
  {
    [actions.setBackgroundImage]: (state, payload) => ({ ...state, backgroundImage: payload }),

    // TODO: move pictures to a separate reducer
    [actions.addPicture]: (state, payload) => ({
      ...state,
      pictures: [
        ...state.pictures,
        { position: { ...defaultPicturePosition }, ...payload, id: uniqueId() },
      ],
    }),
    [actions.removePicture]: (state, payload) => ({
      ...state,
      pictures: state.pictures.filter(p => p.id !== payload.id),
    }),
    [actions.updatePicture]: (state, payload) => ({
      ...state,
      pictures: state.pictures.map(p => (p.id === payload.id ? { ...p, ...payload } : p)),
    }),

    // TODO: move messages to a separate reducer
    [actions.addMessage]: (state, payload) => ({
      ...state,
      messages: [
        ...state.messages,
        { position: { ...defaultMessagePosition }, ...payload, id: uniqueId() },
      ],
    }),
    [actions.removeMessage]: (state, payload) => ({
      ...state,
      messages: state.messages.filter(m => m.id !== payload.id),
    }),
    [actions.updateMessage]: (state, payload) => ({
      ...state,
      messages: state.messages.map(m => (m.id === payload.id ? { ...m, ...payload } : m)),
    }),

    [actions.setCard]: (state, payload) => ({ ...state, ...payload }),
  },
  initialState.card
)

const editorReducer = createReducer(
  {
    [actions.setEmail]: (state, payload) => ({ ...state, email: payload }),
    [actions.setTemplate]: (state, payload) => ({ ...state, template: payload }),
    [actions.setPreview]: (state, payload) => ({ ...state, isPreview: payload }),
    [actions.setStatus]: (state, payload) => ({ ...state, status: payload }),
    [actions.setCardUrl]: (state, payload) => ({ ...state, cardUrl: payload }),
    [actions.setError]: (state, payload) => ({ ...state, error: payload }),
    [actions.dismissAlerts]: state => ({ ...state, status: Status.Default, error: null }),
  },
  initialState.editor
)

export const rootReducer = combineReducers({
  card: cardReducer,
  editor: editorReducer,
})
