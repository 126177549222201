const imageUrl = url => `${process.env.PUBLIC_URL}/images/${url}`

export default [
  {
    theme: 'birthday',
    image: imageUrl('Birthday_Card2.png'),
    placeholders: [
      { x: 50, y: 60, width: 250, height: 330, degree: 354, radius: 0 },
      { x: 340, y: 506, width: 250, height: 330, degree: 10, radius: 0 },
    ],
  },
  {
    theme: 'birthday',
    image: imageUrl('Birthday_Card1.png'),
    placeholders: [
      { x: 98, y: 80, width: 150, height: 190, degree: 0, radius: 0 },
      { x: 390, y: 363, width: 150, height: 190, degree: 0, radius: 0 },
      { x: 103, y: 592, width: 150, height: 190, degree: 0, radius: 0 },
    ],
  },
  {
    theme: 'holidays',
    image: imageUrl('happy_holidays.jpg'),
    placeholders: [],
  },
  {
    theme: 'love',
    image: imageUrl('love_heart.png'),
    placeholders: [{ x: 90, y: 100, width: 420, height: 420, degree: 0, radius: 0 }],
  },
]
