import React from 'react'

const Picture = ({ src, name, width, height, radius, className, zIndex = 0 }) => (
  <div
    className={className}
    style={{
      backgroundImage: `url(${src})`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      borderRadius: `${radius}%`,
      pointerEvents: 'none',
      zIndex,
    }}
  >
    <img
      src={src}
      alt={name}
      width={width || 'auto'}
      height={height || 'auto'}
      style={{
        visibility: 'hidden',
      }}
    />
  </div>
)

export default Picture
