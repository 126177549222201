import axios from 'axios'
import config from '../config'

export const postCard = async data => {
  const url = `${config.apiUrl}/cards`
  const response = await axios.post(url, data)
  return response
}

export const getCard = async cardId => {
  const url = `${config.apiUrl}/cards/${cardId}`
  const response = await axios.get(url)
  return response.data
}
