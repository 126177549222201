const env = process.env.NODE_ENV || 'development'

const config = {
  env,
  apiUrl: process.env.REACT_APP_GREETY_API_URL,
  images: {
    region: 'ap-southeast-2',
    bucketName: 'greety-images',
    maxFileSizeMb: 20,
    maxWidth: 600,
    maxHeight: 800,
    supportedMimeTypes: [
      'image/gif',
      'image/png',
      'image/jpeg',
      'image/bmp',
      'image/webp',
      'image/svg+xml',
    ],
  },
}

export default config
