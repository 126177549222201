import React, { Component } from 'react'
import { Input } from 'reactstrap'
import classNames from 'classnames/bind'
import styles from './Message.module.css'

const cx = classNames.bind(styles)

class Message extends Component {
  handleChange = e => {
    this.props.onChange(e.target.value)
  }

  render() {
    const { text, readonly } = this.props
    return (
      <Input
        type="textarea"
        bsSize="lg"
        className={cx({ default: true, readonly })}
        value={text}
        disabled={readonly}
        placeholder={readonly ? null : 'Enter message here...'}
        onChange={this.handleChange}
      />
    )
  }
}

export default Message
