import React from 'react'
import { connect } from 'react-redux'
import { Card } from 'reactstrap'
import Dropzone from 'react-dropzone'
import { resizeImage, calculatePosition } from '../services/imageService'
import {
  addPicture,
  updatePicture,
  removePicture,
  updateMessage,
  removeMessage,
  setError,
} from '../state/actions'
import Shape from './Shape'
import Message from './Message'
import Picture from './Picture'

const GreetingCard = ({
  backgroundImage,
  pictures,
  messages,
  template,
  readonly,
  onMessageTextChange,
  onMessagePositionChange,
  onMessageRemove,
  onImageDropped,
  onPicturePositionChange,
  onPictureRemove,
}) => (
  <Card className="greeting-card border-0">
    <Picture
      name="background"
      src={backgroundImage}
      className="rounded shadow"
      width="100%"
      zIndex="1"
    />
    {!readonly &&
      template.placeholders.map(placeholder => (
        <Shape key={`${placeholder.x},${placeholder.y}`} position={placeholder} fixed>
          <Dropzone
            className="row h-100"
            style={{ cursor: 'pointer' }}
            multiple={false}
            accept="image/*"
            onDrop={files => onImageDropped(placeholder, files[0])}
          >
            <div className="my-auto p-5">
              Drop an image here or click to select a file to upload.
            </div>
          </Dropzone>
        </Shape>
      ))}
    {messages.map(message => (
      <Shape
        key={message.id}
        position={message.position}
        onChange={position => onMessagePositionChange(message.id, position)}
        onRemove={() => onMessageRemove(message.id)}
        fixed={readonly}
      >
        <Message
          text={message.text}
          onChange={text => onMessageTextChange(message.id, text)}
          readonly={readonly}
        />
      </Shape>
    ))}
    {pictures.map(picture => (
      <Shape
        key={picture.id}
        position={picture.position}
        onChange={position => onPicturePositionChange(picture.id, position)}
        onRemove={() => onPictureRemove(picture.id)}
        lockAspectRatio
        enableRadiusChange
        fixed={readonly}
      >
        <Picture
          name={picture.name}
          src={picture.objectUrl || picture.src}
          width="100%"
          radius={picture.position.radius}
        />
      </Shape>
    ))}
  </Card>
)

const mapStateToProps = ({ card, editor }) => ({
  backgroundImage: card.backgroundImage,
  pictures: card.pictures,
  messages: card.messages,
  template: editor.template,
})

const mapDispatchToProps = {
  onMessageTextChange: (id, text) => dispatch => {
    dispatch(updateMessage({ id, text }))
  },

  onMessagePositionChange: (id, position) => dispatch => {
    dispatch(updateMessage({ id, position }))
  },

  onImageDropped: (placeholder, file) => async dispatch => {
    try {
      const { file: resizedFile, image } = await resizeImage(file)
      const { x, y, width, height } = calculatePosition(image, placeholder)
      const position = { ...placeholder, x, y, width, height }
      dispatch(addPicture({ file: resizedFile, objectUrl: image.src, position }))
    } catch (error) {
      dispatch(setError(error.message))
    }
  },

  onPicturePositionChange: (id, position) => dispatch => {
    const { x, y, width, height, degree, radius } = position
    console.log(
      `x: ${x}, y: ${y}, width: ${width}, height: ${height}, degree: ${degree}, radius: ${radius}`
    )
    dispatch(updatePicture({ id, position }))
  },

  onMessageRemove: id => dispatch => {
    dispatch(removeMessage({ id }))
  },

  onPictureRemove: id => dispatch => {
    dispatch(removePicture({ id }))
  },
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GreetingCard)
