import React from 'react'
import { connect } from 'react-redux'
import { Card, CardBody, CardHeader } from 'reactstrap'
import Dropzone from 'react-dropzone'
import { resizeImage } from '../services/imageService'
import { addPicture, setError } from '../state/actions'

const dropzoneStyle = {
  border: '2px gray dashed',
  borderRadius: '10px',
  cursor: 'pointer',
  padding: '10px',
}

const ImageUploader = ({ onImageDropped }) => (
  <Card className="image-uploader">
    <CardHeader>Upload images</CardHeader>
    <CardBody className="p-1">
      <Dropzone style={dropzoneStyle} multiple={false} accept="image/*" onDrop={onImageDropped}>
        Drop an image here or click to select a file to upload.
      </Dropzone>
    </CardBody>
  </Card>
)

const mapStateToProps = state => ({
  undefined,
})

const mapDispatchToProps = {
  onImageDropped: files => async dispatch => {
    try {
      const file = files[0]
      const { file: resizedFile, image } = await resizeImage(file)
      dispatch(addPicture({ file: resizedFile, objectUrl: image.src }))
    } catch (error) {
      dispatch(setError(error.message))
    }
  },
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImageUploader)
