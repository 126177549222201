import React from 'react'
import { connect } from 'react-redux'
import { Card, CardBody, CardHeader, ListGroup, ListGroupItem } from 'reactstrap'
import { addMessage } from '../state/actions'
import messages from '../messages/birthday'

const MessageSelector = ({ onMessageSelected }) => (
  <Card className="message-selector mt-2">
    <CardHeader>Add message</CardHeader>
    <CardBody className="p-1">
      <ListGroup flush>
        {messages.map(message => (
          <ListGroupItem
            tag="a"
            href="#"
            key={message}
            className="message-snippet p-1"
            title={message}
            onClick={e => onMessageSelected(e, message)}
          >
            {message}
          </ListGroupItem>
        ))}
      </ListGroup>
    </CardBody>
  </Card>
)

const mapStateToProps = state => ({
  undefined,
})

const mapDispatchToProps = {
  onMessageSelected: (e, text) => dispatch => {
    e.preventDefault()
    dispatch(addMessage({ text }))
  },
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MessageSelector)
